export default {
  15: {
    '2024-02-29': {
      data: {
        parkingData: [
          {
            time: '29/Feb/2024(Thu)',
            peopleNumber: '21,208',
            peopleCumulativeTotal: '21,208',
            peopleLastYearSameDateNumber: '19,000',
            peopleLastYearSameWeekNumber: '20,368',
            carNumber: '2,659',
            carCumulativeTotal: '2,659',
            carLastYearSameDateNumber: '1,905',
            carLastYearSameWeekNumber: '2,096',
            bikeNumber: '8,192',
            bikeCumulativeTotal: '8,192',
            bikeLastYearSameDateNumber: '7,844',
            bikeLastYearSameWeekNumber: '8,073',
          },
        ],
        legendData: [
          '29/Feb/2024(Thu)',
        ],
        lastLegendData: [
          '28/Feb/2023(Tue)',
        ],
        lastYearSameWeekData: [
          '02/Mar/2023(Thu)',
        ],
        parkingFooterData: [
          {
            time: 'Total',
            peopleNumber: '21,208',
            peopleCumulativeTotal: '21,208',
            peopleLastYearSameDateNumber: '19,000',
            peopleLastYearSameWeekNumber: '20,368',
            carNumber: '2,659',
            carCumulativeTotal: '2,659',
            carLastYearSameDateNumber: '1,905',
            carLastYearSameWeekNumber: '2,096',
            bikeNumber: '8,192',
            bikeCumulativeTotal: '8,192',
            bikeLastYearSameDateNumber: '7,844',
            bikeLastYearSameWeekNumber: '8,073',
          },
        ],
      },
    },
    '2020-02-29': {
      data: {
        parkingData: [
          {
            time: '29/Feb/2020(Sat)',
            peopleNumber: '0',
            peopleCumulativeTotal: '0',
            peopleLastYearSameDateNumber: '0',
            peopleLastYearSameWeekNumber: '0',
            carNumber: '0',
            carCumulativeTotal: '0',
            carLastYearSameDateNumber: '0',
            carLastYearSameWeekNumber: '0',
            bikeNumber: '0',
            bikeCumulativeTotal: '0',
            bikeLastYearSameDateNumber: '0',
            bikeLastYearSameWeekNumber: '0',
          },
        ],
        legendData: [
          '29/Feb/2020(Sat)',
        ],
        lastLegendData: [
          '28/Feb/2019(Thu)',
        ],
        lastYearSameWeekData: [
          '02/Mar/2019(Sat)',
        ],
        parkingFooterData: [
          {
            time: 'Total',
            peopleNumber: '0',
            peopleCumulativeTotal: '0',
            peopleLastYearSameDateNumber: '0',
            peopleLastYearSameWeekNumber: '0',
            carNumber: '0',
            carCumulativeTotal: '0',
            carLastYearSameDateNumber: '0',
            carLastYearSameWeekNumber: '0',
            bikeNumber: '0',
            bikeCumulativeTotal: '0',
            bikeLastYearSameDateNumber: '0',
            bikeLastYearSameWeekNumber: '0',
          },
        ],
      },
    },
    '2016-02-29': {
      data: {
        parkingData: [
          {
            time: '29/Feb/2016(Mon)',
            peopleNumber: '0',
            peopleCumulativeTotal: '0',
            peopleLastYearSameDateNumber: '0',
            peopleLastYearSameWeekNumber: '0',
            carNumber: '0',
            carCumulativeTotal: '0',
            carLastYearSameDateNumber: '0',
            carLastYearSameWeekNumber: '0',
            bikeNumber: '0',
            bikeCumulativeTotal: '0',
            bikeLastYearSameDateNumber: '0',
            bikeLastYearSameWeekNumber: '0',
          },
        ],
        legendData: [
          '29/Feb/2016(Mon)',
        ],
        lastLegendData: [
          '28/Feb/2015(Sat)',
        ],
        lastYearSameWeekData: [
          '02/Mar/2015(Mon)',
        ],
        parkingFooterData: [
          {
            time: 'Total',
            peopleNumber: '0',
            peopleCumulativeTotal: '0',
            peopleLastYearSameDateNumber: '0',
            peopleLastYearSameWeekNumber: '0',
            carNumber: '0',
            carCumulativeTotal: '0',
            carLastYearSameDateNumber: '0',
            carLastYearSameWeekNumber: '0',
            bikeNumber: '0',
            bikeCumulativeTotal: '0',
            bikeLastYearSameDateNumber: '0',
            bikeLastYearSameWeekNumber: '0',
          },
        ],
      },
    },
    '2024-02-28': {
      data: {
        parkingData: [
          {
            time: '28/Feb/2024(Wed)',
            peopleNumber: '20,887',
            peopleCumulativeTotal: '20,887',
            peopleLastYearSameDateNumber: '19,000',
            peopleLastYearSameWeekNumber: '19,297',
            carNumber: '2,600',
            carCumulativeTotal: '2,600',
            carLastYearSameDateNumber: '1,905',
            carLastYearSameWeekNumber: '1,964',
            bikeNumber: '8,086',
            bikeCumulativeTotal: '8,086',
            bikeLastYearSameDateNumber: '7,844',
            bikeLastYearSameWeekNumber: '7,701',
          },
        ],
        legendData: [
          '28/Feb/2024(Wed)',
        ],
        lastLegendData: [
          '28/Feb/2023(Tue)',
        ],
        lastYearSameWeekData: [
          '01/Mar/2023(Wed)',
        ],
        parkingFooterData: [
          {
            time: 'Total',
            peopleNumber: '20,887',
            peopleCumulativeTotal: '20,887',
            peopleLastYearSameDateNumber: '19,000',
            peopleLastYearSameWeekNumber: '19,297',
            carNumber: '2,600',
            carCumulativeTotal: '2,600',
            carLastYearSameDateNumber: '1,905',
            carLastYearSameWeekNumber: '1,964',
            bikeNumber: '8,086',
            bikeCumulativeTotal: '8,086',
            bikeLastYearSameDateNumber: '7,844',
            bikeLastYearSameWeekNumber: '7,701',
          },
        ],
      },
    },
    '2020-02-28': {
      data: {
        parkingData: [
          {
            time: '28/Feb/2020(Fri)',
            peopleNumber: '0',
            peopleCumulativeTotal: '0',
            peopleLastYearSameDateNumber: '0',
            peopleLastYearSameWeekNumber: '0',
            carNumber: '0',
            carCumulativeTotal: '0',
            carLastYearSameDateNumber: '0',
            carLastYearSameWeekNumber: '0',
            bikeNumber: '0',
            bikeCumulativeTotal: '0',
            bikeLastYearSameDateNumber: '0',
            bikeLastYearSameWeekNumber: '0',
          },
        ],
        legendData: [
          '28/Feb/2020(Fri)',
        ],
        lastLegendData: [
          '28/Feb/2019(Thu)',
        ],
        lastYearSameWeekData: [
          '01/Mar/2019(Fri)',
        ],
        parkingFooterData: [
          {
            time: 'Total',
            peopleNumber: '0',
            peopleCumulativeTotal: '0',
            peopleLastYearSameDateNumber: '0',
            peopleLastYearSameWeekNumber: '0',
            carNumber: '0',
            carCumulativeTotal: '0',
            carLastYearSameDateNumber: '0',
            carLastYearSameWeekNumber: '0',
            bikeNumber: '0',
            bikeCumulativeTotal: '0',
            bikeLastYearSameDateNumber: '0',
            bikeLastYearSameWeekNumber: '0',
          },
        ],
      },
    },
    '2016-02-28': {
      data: {
        parkingData: [
          {
            time: '28/Feb/2016(Sun)',
            peopleNumber: '0',
            peopleCumulativeTotal: '0',
            peopleLastYearSameDateNumber: '0',
            peopleLastYearSameWeekNumber: '0',
            carNumber: '0',
            carCumulativeTotal: '0',
            carLastYearSameDateNumber: '0',
            carLastYearSameWeekNumber: '0',
            bikeNumber: '0',
            bikeCumulativeTotal: '0',
            bikeLastYearSameDateNumber: '0',
            bikeLastYearSameWeekNumber: '0',
          },
        ],
        legendData: [
          '28/Feb/2016(Sun)',
        ],
        lastLegendData: [
          '28/Feb/2015(Sat)',
        ],
        lastYearSameWeekData: [
          '01/Mar/2015(Sun)',
        ],
        parkingFooterData: [
          {
            time: 'Total',
            peopleNumber: '0',
            peopleCumulativeTotal: '0',
            peopleLastYearSameDateNumber: '0',
            peopleLastYearSameWeekNumber: '0',
            carNumber: '0',
            carCumulativeTotal: '0',
            carLastYearSameDateNumber: '0',
            carLastYearSameWeekNumber: '0',
            bikeNumber: '0',
            bikeCumulativeTotal: '0',
            bikeLastYearSameDateNumber: '0',
            bikeLastYearSameWeekNumber: '0',
          },
        ],
      },
    },
  },
}
